import axios from "../api/api";
import { Link } from "react-router-dom";
import Contact from "../components/Contact";
import { useEffect, useState } from "react";

function MainPage() {
    const [Inputdata, setInputdata] = useState("")
    const [data, setData] = useState([]);
    console.log(data)
    const [error, setError] = useState(null);
    // console.log(data)
    // console.log(error)
    useEffect(() => {
        const getApi = () => {
            if (Inputdata && Inputdata.length > 0) {
                axios.get(`http://localhost:5001/product/search/${Inputdata}`)
                    .then(res => {
                        setData(res.data.innerData);
                    })
                    .catch(err => {
                        setError("Error fetching data. Please try again.");
                        setData("");
                        // console.error(err);
                    });
            } else {
                console.log("Fill in the fields");
            }
        };
        getApi();
    }, [Inputdata]);



    return (
        <div className="flags w-full h-screen bg-center bg-cover relative">
            <div className="absolute bg-black/40 w-full h-full top-0 left-0 "></div>
            <div className="text-white z-100 absolute w-full h-full">
                {/* <Contact /> */}
                <div className="w-[300px]   min-[350px]:w-[350px]  mx-auto mt-[150px] text-center text-2xl font-bold leading-10">
                    <form >
                        <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input onChange={(e) => setInputdata(e.target.value)} type="search" value={Inputdata} id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Maxsulot nomi kiriting..." required />
                            <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                        </div>
                    </form>
                </div>
                {data.length > 0 && Inputdata.length > 0 ?
                    <div className="w-3/4 mx-auto mt-10 relative overflow-x-auto">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Maxsulot nomi
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Maxsulot narxi
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map(pro => (
                                    <tr className="bg-white">
                                        <th scope="col" className="px-6 py-3">
                                            {pro.productname}
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            {pro.productprice}
                                        </th>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                    : <></>}
            </div>
        </div >
    );
}

export default MainPage;
