import axios from '../../api/api'
import React, { useContext, useEffect, useState } from 'react'
import { FaPenToSquare } from "react-icons/fa6";
import { toast } from 'react-toastify'
import { AuthContext } from '../../context/AuthContext';
import { FaExchangeAlt } from "react-icons/fa";
function Dashboard() {
    const [Inputdata, setInputdata] = useState("")
    const [changeOption, setChangeOption] = useState(false)

    const { setIsLoading } = useContext(AuthContext)

    const [data, setData] = useState([]);
    console.log(data)
    const [error, setError] = useState(null);
    // console.log(data)
    // console.log(error)
    useEffect(() => {
        const getApi = async () => {
            if (Inputdata && Inputdata.length > 0) {
                await axios.get(`/product/search/${!changeOption ? "productname" : "barcode"}/${Inputdata}`)
                    .then(res => {
                        setData(res.data.innerData);
                        console.log(res)
                    })
                    .catch(err => {
                        setError("Error fetching data. Please try again.");
                        // setData("");
                        // console.error(err);
                    });
            } else {
                console.log("Fill in the fields");
            }
        };
        getApi();

    }, [Inputdata]);


    const SingleItem = ({ pro }) => {
        const [switcher, setSwitcher] = useState(false)
        const [inName, setInName] = useState("")
        const [inPrice, setInPrice] = useState("")

        const UpdateProduct = async (e) => {
            setIsLoading(true)
            try {
                await axios.put(`/product/update/${pro._id}`, {
                    inName,
                    inPrice
                }
                    // {
                    //     headers: {
                    //         'Authorization': `Bearer ${user.token}`
                    //     }
                    // }
                )
                    .then(res => {
                        toast.success(res.data, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setIsLoading(false)
                        setInputdata("")
                        console.log(res)
                    })
                    .catch((error) => {
                        toast.error(error.response.data, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setIsLoading(false)
                        console.log(error)
                    })
            } catch (error) {
                toast.error(('Error:', error.response.data), {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

        }

        return <tr className="bg-white">
            <th scope="col" className="px-6 py-3">
                {pro.productname}
            </th>
            <th scope="col" className="px-6 py-3 w-40">
                {switcher ?
                    <input onChange={(e) => setInName(e.target.value)} type="text" id="default-input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" /> : <></>}
            </th>
            <th scope="col" className="px-6 py-3">
                {pro.productprice}
            </th>
            <th scope="col" className="px-6 py-3 w-20">
                {switcher ? <input onChange={(e) => setInPrice(e.target.value)} type="text" id="default-input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" /> : <></>}

            </th>
            <th scope="col" className="px-6 py-3">
                {pro.barcode}
            </th>
            <th>

                <button onClick={() => !switcher ? setSwitcher(true) : setSwitcher(false)} type="button" className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"> <FaPenToSquare /></button>

            </th>
            <th className="px-6 py-3 w-40 h-20">
                {switcher ?
                    <button onClick={UpdateProduct} type="button" className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">saqlash</button> : <></>}
            </th>
            <th className="px-6 py-3 w-40 h-20">
                {switcher ?
                    <button type="button" className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800">O'chirish</button> : <></>}
            </th>

        </tr>
    }

    return (
        <div className="p-4 sm:ml-64">
            <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-16">
                <div className="text-white  w-full h-full">
                    {/* <div className="w-[300px]   min-[350px]:w-[350px]  mx-auto mt-[150px] text-center text-2xl font-bold leading-10"> */}
                    <div >
                        <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input onChange={(e) => setInputdata(e.target.value)} type="search" value={Inputdata} id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={!changeOption ? "Maxsulot nomi orqali" : "Bar code orqali qidirish"} required />
                            <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                        </div>

                    </div>
                    <div className="flex">
                        <button onClick={() => !changeOption ? setChangeOption(true) : setChangeOption(false)} type="submit" className="text-white  end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"><FaExchangeAlt /></button> <p className='text-black ml-5'>{changeOption ? "Maxsulot nomi orqali" : "Bar code orqali qidirish"}</p>
                    </div>


                    <div className="w-full mx-auto mt-10 relative overflow-x-auto">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Maxsulot nomi
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        nomi o'zgartirish
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Maxsulot narxi
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Narxini o'zgartirish
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        barcode
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        taxrirlash
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        saqlash
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        delete
                                    </th>
                                </tr>

                            </thead>
                            {data.length > 0 && Inputdata.length > 0 ?
                                <tbody>

                                    {data?.map(pro => (
                                        <SingleItem pro={pro} />
                                    ))}
                                </tbody>
                                : <></>}

                        </table>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Dashboard