import axios from '../../api/api'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
function HistoryComponent() {
    const [history, setHistory] = useState([])
    console.log(history)
    const [date, setDate] = useState(null);
    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.post(`history/gethistory`, {
                    date
                });
                console.log(response)
                if (response.data && response.data.length > 0) {
                    // setHistory(response.data);
                    let allday = []
                    response.data.forEach(item => {
                        console.log(item)
                        const objectId = item._id; // Assuming _id is the ObjectID
                        const timestamp = parseInt(objectId.substring(0, 8), 16); // Extract and convert timestamp
                        const date = new Date(timestamp * 1000); // Convert to milliseconds
                        // Format date to 'YYYY.MM.DD'
                        const formattedDate = date.getFullYear() + '-' +
                            ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
                            ('0' + date.getDate()).slice(-2);
                        // console.log(formattedDate)
                        if (item.data && item.data.length > 0) {
                            const totalPrice = item.data.reduce((acc, current) => {
                                return acc + Number(current.productprice);
                            }, 0);
                            allday.push({
                                totalPrice,
                                id: item._id,
                                formattedDate
                            })
                        }
                    });
                    const totalPrice = allday.reduce((acc, current) => {
                        return acc + Number(current.totalPrice);
                    }, 0);
                    // console.log(totalPrice)
                    setHistory({ totalPrice, dayHistory: allday[0] })
                } else {
                    console.log("No data found for the selected date");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        getData();
    }, [date]);




    return (
        <div className="p-4 sm:ml-64">
            <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-16">
                <div className="grid grid-cols-1 gap-4 mb-4">
                    <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                        <h1 className="text-2xl text-gray-800 dark:text-gray-200">
                            Sotuv tarixi
                        </h1>
                    </div>
                    <input type="date" onChange={(e) => setDate(e.target.value)} name="" id="" />
                </div>
                <div className="grid  md:grid-cols-2 gap-4 mb-4">
                    <div className="flex items-center justify-center rounded bg-gray-50 dark:bg-gray-800 p-5">
                        {
                            // history.map(history => (
                            <li className='list-none'>
                                <h2 className="text-2xl text-gray-800 dark:text-gray-200">
                                    <h2>{history?.dayHistory?.formattedDate}</h2>
                                </h2>

                                <Link className='text-2xl text-gray-800 dark:text-gray-200' to="#">
                                    {history?.totalPrice}
                                </Link>
                            </li>
                            // ))
                        }

                    </div>



                </div>
            </div>



        </div>
    )
}

export default HistoryComponent